import moment from "moment";

export default function formatarData(data) {
    let dt = data;
    if (!!data && !moment.isMoment(data))
        dt = moment(data);

    return {
        DIA: dt.format("DD"),
        MES: dt.format("MM"),
        ANO: dt.format("YYYY"),
        MESANO: dt.format("MM/YYYY"),
        DATA: dt.format("DD/MM/YYYY"),
        DATAHORA: dt.format("DD/MM/YYYY HH:mm"),
        DATAHORASEGUNDOS: dt.format("DD/MM/YYYY HH:mm:ss"),
    }
}

