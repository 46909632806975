import React from "react";
import { InputPadrao, LabelPadrao } from "../../components/Base";
import { Input, Form } from "antd";
import styles from "./PessoaForm.module.scss";
import InputMask from "../../components/InputMask";

export default function PessoaForm({ object, setObject }) {
  return <div className={styles.cadastroForm}>
    <div style={{ marginRight: "10px" }}>
      <LabelPadrao>Código</LabelPadrao>
      <InputPadrao value={object.codigo} disabled />
    </div>
    <div style={{ marginRight: "10px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", marginRight: 10 }}>
          <LabelPadrao>Razão social</LabelPadrao>
          <Form.Item name="razaoSocial" rules={[{ type: "string", message: "Insira a razão social", required: true, whitespace: true, }]}>
            <InputPadrao placeholder="Insira a razão social" onChange={e => setObject({ ...object, razaoSocial: e.target.value })} maxLength={60} tabIndex={1} />
          </Form.Item>
        </div>
        <div style={{ width: "50%" }}>
          <LabelPadrao>Nome</LabelPadrao>
          <Form.Item name="nome" rules={[{ type: "string", message: "Insira o nome", required: true, whitespace: true, }]}>
            <InputPadrao placeholder="Insira o nome" onChange={e => setObject({ ...object, nome: e.target.value })} maxLength={60} tabIndex={1} />
          </Form.Item>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%", marginRight: "10px" }}>
          <LabelPadrao>CNPJ</LabelPadrao>
          <Form.Item name="cnpj" rules={[{ type: "string", message: "Insira o cnpj", required: true, whitespace: true, }]}>
            <InputMask placeholder="Insira o CNPJ" mask="99.999.999/9999-99" onChange={e => setObject({ ...object, cnpj: e.target.value })} tabIndex={3} />
          </Form.Item>
        </div>
        <div style={{ width: "100%", marginRight: "10px" }}>
          <LabelPadrao>Inscrição estadual</LabelPadrao>
          <InputMask maskChar={null} placeholder="Insira a inscrição estadual" mask="999999999999" value={object.inscricaoEstadual} onChange={e => setObject({ ...object, inscricaoEstadual: e.target.value })} tabIndex={4} />
        </div>
        <div style={{ width: "100%" }}>
          <LabelPadrao>Responsável</LabelPadrao>
          <Form.Item name="responsavel" rules={[{ type: "string", message: "Insira o responsável", required: false, whitespace: true, }]}>
            <InputPadrao onChange={e => setObject({ ...object, responsavel: e.target.value })} tabIndex={1} />
          </Form.Item>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%", marginRight: 10 }}>
          <LabelPadrao>Telefone</LabelPadrao>
          <InputMask placeholder="Insira o telefone" mask="(99) 99999-9999" value={object.telefone} onChange={e => setObject({ ...object, telefone: e.target.value })} />
        </div>
        <div style={{ width: "100%", marginRight: 10 }}>
          <LabelPadrao>Celular</LabelPadrao>
          <InputMask placeholder="Insira o celular" mask="(99) 99999-9999" value={object.celular} onChange={e => setObject({ ...object, celular: e.target.value })} />
        </div>
        <div style={{ width: "100%", marginRight: 10 }}>
          <LabelPadrao>Email Responsável</LabelPadrao>
          <Form.Item style={{ margin: 0, padding: 0 }} name="emailResponsavel" rules={[{ type: "string", message: "Insira o email", whitespace: true, }]}>
            <InputPadrao data-cy="input-email-resp" tabIndex={10} placeholder="Insira um email" value={object.emailResponsavel} onChange={e => setObject({ ...object, emailResponsavel: e.target.value })} />
          </Form.Item>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%", marginRight: 10 }}>
          <LabelPadrao>Nome financeiro</LabelPadrao>
          <Form.Item name="nomeFinanceiro" rules={[{ type: "string", message: "Insira o nome financeiro", required: false, whitespace: true, }]}>
            <InputPadrao placeholder="Insira o nome financeiro" onChange={e => setObject({ ...object, nomeFinanceiro: e.target.value })} maxLength={60} tabIndex={1} />
          </Form.Item>
        </div>
        <div style={{ width: "100%" }}>
          <LabelPadrao>Email Financeiro</LabelPadrao>
          <Form.Item style={{ margin: 0, padding: 0 }} name="email" rules={[{ type: "string", message: "Insira o email", whitespace: true, }]}>
            <InputPadrao data-cy="input-email" tabIndex={8} placeholder="Insira um email" value={object.email} onChange={e => setObject({ ...object, email: e.target.value })} />
          </Form.Item>
        </div>
      </div>
    </div>

  </div>;
}
