export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {};
    case 'LOGOUT':
      return {};
    case 'SETDADOSPESQ':
      return { ...state, [action.name]: action.dados };
    default:
      return state;
  }
};