import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchFin } from "../../actions";
import { Upload } from "antd";
import { useSave } from "../../components/Hooks/Conexao";
import { ModalConfirmPadrao } from "../../components/Base";

function UploadPadrao({ dados, success, onOk, modalConfirmTitle, ...props }) {
    const [fileList, setFileList] = useState([]);
    const [save, saving] = useSave();

    const salvar = (formData) => save(() => props.fetchFin().post("/file/save", formData), v => {
        if (success) success(v.data);
        onOk();
    });

    useEffect(() => {
        if (fileList && fileList.length > 0) {
            const formData = new FormData();
            formData.append("dados", JSON.stringify(dados));
            fileList.forEach(file => formData.append("files[]", file));

            if (modalConfirmTitle) ModalConfirmPadrao(modalConfirmTitle, `Nome do arquivo: ${fileList[0]?.name}`, "Sim", "Não", () => salvar(formData));
            else salvar(formData);

            setFileList([]);
        }
    }, [fileList]);

    const uploadProps = {
        onRemove: () => setFileList([]),
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    return <Upload {...uploadProps} showUploadList={false} disabled={saving}>
        {props.children}
    </Upload>;
}

export default connect(null, { fetchFin })(UploadPadrao);
