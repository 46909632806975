import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchFin, logIn } from "../../actions";
import { BtnPadrao, Header, InputPadrao, LabelPadrao, LoadingContainerPadrao, ModalPadrao } from "../../components/Base";
import Container from "../../components/Container";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import TabelaPadrao from "../../components/TabelaPadrao";

function PlanoContas(props) {
    const [filtros, setFiltros] = useState("");
    const [id, setId] = useState(null);
    const [dados, listar, loading, setDados] = useFetch(() => props.fetchFin().post("planocontas/getlist", { filtro: filtros }));
    const [salvar, salvando] = useSave(() => props.fetchFin().post("planocontas/inserir", detalhe), () => { setId(null); listar(); });
    const [detalhe, listarDetalhe, loadingDetalhe, setDetalhe] = useFetch(() => props.fetchFin().get(`planocontas/${id}`));

    useEffect(() => listar(), []);
    useEffect(() => { if (id) listarDetalhe(); }, [id]);

    const deletar = v => salvar(() => props.fetchFin().post(`planocontas/deletar/${id}`), () => {
        const aux = [...dados];
        aux.splice(aux.findIndex(x => x.id === v.id), 1);
        setDados(aux);
    });

    return <>
        <Container loading={loading || salvando} >
            <Header title="Plano de Contas" onChange={(v) => setFiltros(v.target.value)} onSearch={() => listar()} onClick={() => setId(-1)} />
            <TabelaPadrao style={{ marginTop: 25 }} columns={columns} data={dados} setEdit={(v) => setId(v.id)} onDelete={deletar} />
        </Container>
        <ModalPadrao footer={<>
            <BtnPadrao type="primary" loading={salvando} onClick={salvar}>Gravar</BtnPadrao>
            <BtnPadrao type="primary" ghost onClick={() => setId(null)} disabled={salvando}>Sair</BtnPadrao>
        </>} visible={id} onCancel={() => setId(null)} footter={null}>
            <LoadingContainerPadrao loading={loadingDetalhe}>
                <LabelPadrao noMargin>Nome</LabelPadrao>
                <InputPadrao placeholder="Insira o nome" value={detalhe?.nome} onChange={v => setDetalhe({ ...detalhe, nome: v.target.value })} />
            </LoadingContainerPadrao>
        </ModalPadrao>
    </>;
}

export default connect(null, { logIn, fetchFin })(PlanoContas);

const columns = [
    {
        Header: "Nome",
        accessor: "nome",
    },
];
