import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchFin, fetch, setDadosPesq } from "../../actions";
import { LabelPadrao } from "../Base";
import SelectBasico from "./SelectBasico";
import { useFiltros } from "./SelectHooks";

function Func(props) {
  const [list, setList] = useState([])
  const [loading, setDados] = useFiltros(!!props.fetchFin ? props.fetchFin : props.fetch, props.modelo, props.pesq, props.setDadosPesq, null, props.alwaysFetch)

  useEffect(() => {
    if (!!props.pesq[props.modelo]) {
      let aux = props.pesq[props.modelo];
      if (props.prependItens) aux = [...props.prependItens, ...aux];
      if (!!props.filter) aux = aux.filter(x => x[props.filter.prop] === props.filter.value);
      if (props.modelo == 'empresa' && aux.length === 1) props.onChange(aux[0].id);
      if (!!props.selectLast && props.pesq[props.modelo + 'Selected']) props.onChange(props.pesq[props.modelo + 'Selected']);

      setList(aux);
    }
  }, [props.pesq[props.modelo], props.filter])

  const onChange = e => {
    if (!!props.selectLast) props.setDadosPesq(props.modelo + 'Selected', e);
    props.onChange(e);
  }

  return <>
    {props.title && <LabelPadrao noMargin>{props.title}</LabelPadrao>}
    <SelectBasico
      placeholder={props.placeholder}
      multiNivel={props.modelo === 'grupoconta' || props.modelo === 'tipolan-nivel'}
      disabled={props.disabled}
      dataCy={props.dataCy}
      dados={list}
      setDados={setDados}
      value={props.value || undefined}
      onChange={onChange}
      loading={loading}
      mode={props.mode}
      allowClear={props.allowClear}
    />
  </>
}

const mapStateToProps = (state) => {
  return { pesq: state.pesq };
};

export default connect(mapStateToProps, { fetch, fetchFin, setDadosPesq })(Func);


