import React from 'react'
import { Result, Button } from 'antd';

export default function ErrorScreen() {

  return <Result
    status="500"
    title="500"
    subTitle="Desculpe, tivemos uma falha no processamento..."
    extra={<div><Button style={{ fontSize: 20, marginTop: 15 }} type="link" onClick={() => document.location.reload(true)}>Tentar novamente</Button></div>}
  />
}
