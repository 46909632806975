const INITIAL_STATE = {
  user: null,
  tokenId: null,
  refreshToken: null,
  reload: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGINATU':
      return { ...state, reload: state.reload + 1 };
    case 'LOGIN':
      return { ...state, ...action.dados };
    case 'LOGOUT':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};