import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { logIn, fetchFin } from "../../actions";
import Container from "../../components/Container";
import FullScreenDialog from "../../components/DialogPadrao";
import ClienteDetalhe from "./ClienteDetalhe";
import TabelaPadrao from "../../components/TabelaPadrao";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import { SearchInputPadrao } from "../../components/Base";
import { BtnAdd, IconAtivo } from './../../components/Base';
import { Button, Drawer } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';

const { Option } = Select;

function ClienteCad(props) {
    const [filtro, setFiltro] = useState({ status: 'ativo', txt: '' });
    const [id, setId] = useState(null);
    const [dados, listar, loading, setDados] = useFetch(() => props.fetchFin().post("cliente/getlist", filtro));
    const [detalhe, listarDetalhe, loadingDetalhe, setDetalhe] = useFetch(() => props.fetchFin().get(`cliente/${id}`));
    const [salvar, salvando] = useSave(() => props.fetchFin().post("cliente/inserir", detalhe), () => { setId(null); setDetalhe(null); listar(); });
    const [trocarStatus, loadingStatus] = useSave(null, () => { setId(null); setDetalhe(null); listar(); });

    useEffect(() => listar(), []);
    useEffect(() => { if (id) listarDetalhe(); }, [id]);

    return <>
        <Container loading={loading || loadingDetalhe || salvando || loadingStatus} >
            <Header dados={filtro} onChange={(f) => setFiltro(f)} onSearch={() => listar()} onAdd={() => setId(-1)} />
            <div style={{ marginTop: 25 }}>
                <TabelaPadrao columns={columns} data={dados} setEdit={(v) => setId(v.id)} onDelete={v => trocarStatus(() => props.fetchFin().get(`cliente/ChangeStatus/${v.id}`))} />
            </div>
        </Container>
        <FullScreenDialog visible={!!detalhe} saving={salvando} onCancel={() => { setDetalhe(null); setId(null); }} title="Cadastro de Cliente" onOk={() => document.getElementById("btnFinalizarHide").click()}>
            <ClienteDetalhe cliente={detalhe ? detalhe : {}} setCliente={setDetalhe} onOk={salvar} />
        </FullScreenDialog>
    </>;
}

export default connect(null, { logIn, fetchFin })(ClienteCad);


function Header({ onAdd, onSearch, dados, onChange }) {
    const [filtrosVisible, setFiltrosVisible] = useState(false);

    return (<>
        <div style={{ width: "100%", display: "flex" }}>
            <div style={{ display: "flex", alignItems: "end", marginRight: 10 }}>
                <div>
                    <Button type="primary" ghost shape="circle" onClick={() => setFiltrosVisible(true)}><RightOutlined /></Button>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <h3>Clientes</h3>
                <SearchInputPadrao
                    onSearch={value => onSearch(value)}
                    onChange={v => onChange({ ...dados, txt: v.target.value })}
                    placeholder="Pesquisar"
                    style={{ maxWidth: '600px' }}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", }} >
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                    <BtnAdd onClick={onAdd} />
                </div>
            </div>
        </div>
        <Drawer
            title="Filtros"
            placement='left'
            width={400}
            onClose={() => setFiltrosVisible(false)}
            visible={filtrosVisible}
        >
            <div>Status</div>
            <Select value={dados.status} style={{ width: 120 }} onChange={v => onChange({ ...dados, status: v })}>
                <Option value="ativo">Ativo</Option>
                <Option value="inativo">Inativo</Option>
                <Option value="todos">Todos</Option>
            </Select>
        </Drawer>
    </>);
}

const columns = [
    {
        Header: "Código",
        accessor: "codigo",
    },
    {
        Header: "Nome",
        accessor: "nome",
    },
    {
        Header: "CNPJ",
        accessor: "cnpj",
        tipo: "cpfCnpj"
    },
    {
        Header: "Banco",
        accessor: "banco",
        tipo: "right"
    },
    {
        Header: "Status",
        accessor: "isExcluido",
        tipo: "right",
        Cell: props => <IconAtivo isAtivo={!props.row.original.isExcluido} />,
    }
];
