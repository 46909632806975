import React, { useState } from "react";
import { Form, message } from "antd";
import { BtnPadrao } from "../../components/Base";
import InputMask from "../../components/InputMask";

const BuscarCep = ({ cep, setCep, onOk, required, disableForm }) => {
    const [loading, setLoading] = useState(false);
    const getCep = async () => {
        setLoading(true);
        const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json())
            .then(res => (res.cep ? res : message.error("cep não econtrado")))
            .catch(err => {
                message.error("cep não encontrado");
                // console.log(err);
            });
        onOk(res);
        setLoading(false);
    };
    return (
        <div style={{ display: "flex" }}>
            {!disableForm ? <Form.Item name="cep" rules={[{ type: "string", message: "Insira o número", required: required, whitespace: true, }]}>
                <InputMask
                    placeholder="Insira o CEP"
                    data-cy="input-cep"
                    mask="99999-999"
                    value={cep}
                    onKeyPress={e => { if (e.key === "Enter") getCep(); }}
                    onChange={e => setCep(e.target.value)}
                    disabled={loading}
                    tabIndex={9}
                />
            </Form.Item> : <div>
                <InputMask
                    placeholder="Insira o CEP"
                    data-cy="input-cep"
                    mask="99999-999"
                    value={cep}
                    onKeyPress={e => { if (e.key === "Enter") getCep(); }}
                    onChange={e => setCep(e.target.value)}
                    disabled={loading}
                    tabIndex={9}
                />
            </div>}

            <div>
                <BtnPadrao data-cy="btn-cep" type="primary" ghost style={{ marginLeft: 10 }} onClick={() => getCep()} loading={loading}>
                    Buscar CEP
                </BtnPadrao>
            </div>
            <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php?t"
                onClick={e => {
                    e.preventDefault();
                    window.open(e.target.href);
                }}
                style={{ padding: 5 }}
            >
                Não sei
            </a>
        </div>

    );
};

export default BuscarCep;
