import React from "react";
import { connect } from 'react-redux';
import { fetchAgreg } from '../../actions';
import Container from "../../components/Container";
import { useSave } from "../../components/Hooks/Conexao";
import { Divider } from "antd";
import { BtnPadrao } from "../../components/Base";
import mensagens from "../../components/Mensagens";

function Tools(props) {
    const [reiniciar, isLoading] = useSave(() => props.fetchAgreg().get("seed"), () => mensagens.showSucesso('Reiniciado com sucesso!'));
    return <Container {...props} loading={isLoading}>
        <Divider orientation="left">Reiniciar Base de dados</Divider>
        <BtnPadrao type="primary" ghost onClick={reiniciar}>Reiniciar</BtnPadrao>
    </Container>;
}

export default connect(null, { fetchAgreg })(Tools);
