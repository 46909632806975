import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log("error", error);
    console.log("info", info);
  }

  render() {
    if (this.state.hasError) return <h1>Algo deu errado, por favor tente novamente em instantes.</h1>;
    return this.props.children;
  }
}
