import { useState, useEffect } from "react";

export function useFiltros(fetch, modelo, pesq, setDadosPesq, id, alwaysFetch) {
    const [loading, setLoading] = useState(false);
    const modeloTratado = modelo + (id ? id : '');
    const setDados = (d) => setDadosPesq(modeloTratado, d);

    useEffect(() => {
        if (!pesq[modeloTratado] || alwaysFetch) {
            setLoading(true);
            fetch()
                .post(`/${modelo}/getlist`, { txt: (id || "").toString() })
                .then((response) => {
                    setLoading(false);
                    setDados(response.data);
                })
                .catch(() => setLoading(false));
        }
    }, []);

    return [loading, setDados]
}