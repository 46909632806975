import React, { useState } from "react";
import styles from "./Login.module.scss";
import { connect } from "react-redux";
import { logIn, fetch } from "../../actions";
import { Input, Button, message, Avatar } from "antd";
import { LoadingScreen } from "../../components/Diversos";
import * as api from "./AuthApi";

function Login(props) {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    login();
  };

  const login = async () => {
    if (loading) return;

    localStorage.clear();
    setLoading(true);

    try {
      const response = await api.login(props.fetch, { user, pass, sistema: "adm" });
      const data = response.data;

      props.logIn({ user: data.userName, tokenId: data.tokenId, refreshToken: data.refreshToken });
      props.history.push("/");
    } catch (error) {
      console.log(error);
      message.error("Falha ao efetuar o login");
    }

    setLoading(false);
  };

  return (
    <>
      <LoadingScreen loading={loading} msg="Verificando login..." />
      <form onSubmit={handleSubmit}>
        <div className={styles.main}>
          <div className={styles.box}>
            <div style={{ textAlign: "center" }}>
              <Avatar size={80} icon="U" style={{ marginBottom: 30 }} />
            </div>
            <div style={{ textAlign: "center" }}>
              <h4>Entrar</h4>
            </div>
            <div>
              <Input data-cy="input-user" type="text" placeholder="Usuário" className="form-control" value={user} onChange={e => setUser(e.target.value)} />
            </div>
            <div style={{ marginTop: 20 }}>
              <Input data-cy="input-password" type="password" placeholder="Senha" className="form-control" value={pass} onChange={e => setPass(e.target.value)} />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button data-cy="btn-login" className={styles.btnLogin} type="primary" ghost htmlType="submit">
                Entrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default connect(null, { logIn, fetch })(Login);
