import React from "react";
import { Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import { IoIosExit } from "react-icons/io";
const { SubMenu } = Menu;

function Func() {
  const current = [];
  const handleClick = () => { };

  return <div style={{ position: "fixed", width: "100%", zIndex: 950 }}>
    <Menu mode="horizontal" theme="dark" onClick={handleClick} selectedKeys={current}>
      <Menu.Item key="Logo">
        <NavLink to="/">
          <span style={{ fontSize: "1.2em", marginRight: 8, color: "white", textDecoration: "underline" }}>GFC Financeiro</span>
          {/* <img src={Logo} alt="Logo" style={{ maxHeight: 22, marginTop: "-5px" }} /> */}
        </NavLink>
      </Menu.Item>

      <SubMenu title="Financeiro">
        <Menu.Item key="setting:5">
          <NavLink to="/clientecad">Cadastro Cliente</NavLink>
        </Menu.Item>
        <Menu.Item key="setting:9">
          <NavLink to="/planocontas">Plano de Contas</NavLink>
        </Menu.Item>
        <Menu.Item key="setting:6">
          <NavLink to="/contas">Contas a Receber</NavLink>
        </Menu.Item>
        <Menu.Item key="setting:7">
          <NavLink to="/pregerar">Pré-Gerar Boleto</NavLink>
        </Menu.Item>
        <Menu.Item key="setting:8">
          <NavLink to="/gerar">Gerar Boleto</NavLink>
        </Menu.Item>
      </SubMenu>
      <div style={{ position: "absolute", right: 10 }}>
        <SubMenu icon={<UserOutlined className="hover-padrao" style={{ fontSize: 20, marginTop: 15 }} />} key="configs" >
          <Menu.Item key="4" data-cy="logout-dropdown-item"> <NavLink to="/sair">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}> <IoIosExit size={20} /> <span>Sair</span></div></NavLink>
          </Menu.Item>
        </SubMenu>
      </div>
    </Menu>
  </div>;
}

export default Func;
