import { Divider, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchFin, logIn } from "../../actions";
import { BtnPadrao, LabelPadrao, LoadingContainerPadrao, ModalConfirmPadrao, ModalPadrao } from "../../components/Base";
import Container from "../../components/Container";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import TabelaPadrao from "../../components/TabelaPadrao";

function GerarBoleto({ tipo, ...props }) {
    const [filtros, setFiltros] = useState("PRE");
    const [marcados, setMarcados] = useState([]);
    const [dados, listarDados, isLoading] = useFetch(() => props.fetchFin().post("boleto/lote", { status: filtros }));
    const [detalhe, listarDetalhe, isLoadingDetalhe, setDetalhe] = useFetch();
    const [gerar, gerando] = useSave(() => props.fetchFin().post(`boleto/${filtros === "GERADO" ? "enviar" : "gerar"}/${marcados[0]}`),
        v => { listarDados(); setDetalhe(null); setMarcados([]); if (filtros === "PRE") downloadTxtFile(v); });

    const listarDet = v => listarDetalhe(() => props.fetchFin().get(`boleto/${v.id}`));
    const deletar = v => gerar(() => props.fetchFin().post(`boleto/cancelar/${v.id}`), () => { listarDados(); });

    const visualizarBoleto = v => {
        if(filtros === "ENVIADO" || filtros === "GERADO") {
            if(!!v.boletoUrl){
                window.open(v.boletoUrl);
            }
            else{
                gerar(() => props.fetchFin().get(`boleto/pdf/${v.id}`), v => openPdfFile(v))
            }

        }
    }

    useEffect(() => { listarDados(); setMarcados([]); }, [filtros]);

    return <Container loading={isLoading || gerando || isLoadingDetalhe}>
        <Divider orientation="left">Gerar boleto</Divider>
        <LabelPadrao>Filtrar status</LabelPadrao>
        <div style={{ display: "flex", alignItems: "flex-end" }} >
            <Select style={{ width: "15%" }} value={filtros} onChange={(v) => setFiltros(v)}>
                <Select.Option value="PRE">Pré-gerado</Select.Option>
                <Select.Option value="GERADO">Remessa Pré-Gerada</Select.Option>
                <Select.Option value="ENVIADO">Enviado</Select.Option>
                <Select.Option value="CANCELADO">Cancelado</Select.Option>
            </Select>
            <div style={{ flex: 2, textAlign: 'right' }}>
                {filtros !== "ENVIADO" && <BtnPadrao style={{ marginTop: 10 }} disabled={!marcados.length} type="primary"
                    onClick={() => ModalConfirmPadrao(`${filtros !== "GERADO" ? "Gerar" : "Enviar"} lote?`, `Um vez ${filtros !== "GERADO" ? "GERADO" : "ENVIADO"} não poderá ser CANCELADO!`, "Confirmar", "Cancelar", () => gerar())}>{(filtros === "GERADO" ? "Enviar" : "Gerar")}</BtnPadrao>}
            </div>
        </div>

        {(filtros === "ENVIADO" || filtros === "CANCELADO") && <TabelaPadrao style={{ marginTop: 25 }} data={dados} columns={columns} setEdit={listarDet} />}
        {(filtros === "PRE") && <TabelaPadrao columns={columns} style={{ marginTop: 25 }} data={dados} setMarcados={setMarcados} onDelete={deletar} checkOnlyOne />}
        {(filtros === "GERADO") && <TabelaPadrao columns={columns} style={{ marginTop: 25 }} data={dados} setMarcados={setMarcados} setEdit={listarDet} onDelete={deletar} checkOnlyOne />}

        <ModalPadrao width={700} footer={null} visible={detalhe} onCancel={() => setDetalhe(null)} title="Boletos">
            <LoadingContainerPadrao loading={gerando}>
                {/* <pre>{JSON.stringify(detalhe, null, 2)}</pre> */}
                <div style={{ minHeight: 100 }}>
                    {!!detalhe && <TabelaPadrao columns={columnsDet} style={{ marginTop: 10 }} data={detalhe} setView={visualizarBoleto} />}
                </div>
            </LoadingContainerPadrao>
        </ModalPadrao>
    </Container>;
}

export default connect(null, { logIn, fetchFin })(GerarBoleto);

const downloadTxtFile = (v) => {
    const element = document.createElement("a");
    const file = new Blob([v], { type: 'text/html' });
    element.href = URL.createObjectURL(file);
    element.download = "remessa.rem";
    document.body.appendChild(element);
    element.click();
};

const openPdfFile = (data) => {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i);
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
};

const columnsDet = [
    {
        Header: "Nome",
        accessor: "nome",
    },
    {
        Header: "Data vencimento",
        accessor: "vencimento",
        tipo: "dt"
    },
    {
        Header: "Valor",
        accessor: "valor",
        tipo: "rs"
    }
];

const columns = [
    {
        Header: "Descrição",
        accessor: "descricao",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: props => {
            const c = props.row.original.status;
            return <span style={{ color: (c === "PRE" && "blue") || (c === "GERADO" && "#ebb434") || (c === "ENVIADO" && "green") || (c === "CANCELADO" && "red") }}>{c}</span>;
        },
    }
];