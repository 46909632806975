import { message } from "antd";

message.config({
  duration: 2,
  maxCount: 1,
});

const mensagens = {
  showSucesso: msg => {
    return message.success(msg);
  },

  showWarning: msg => {
    return message.warning(msg);
  },

  showErro: msg => {
    return message.error(msg);
  },
};

export default mensagens;
