import React from "react";
import SpinnerLogoMain from "../assets/Spinner.gif";

export function LoadingScreenBlank({ loading, msg }) {
  return <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.70)", zIndex: 900, display: loading ? "block" : "none" }}>
    <div style={{ top: "45%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute", textAlign: "center" }}>
      {msg ? <h4 style={{ marginTop: 20 }}>{msg}</h4> : null}
    </div>
  </div>
}

export function Spinner(props) {
  return <img src={SpinnerLogoMain} alt="loading..." {...props} />;
}

export function LoadingScreen({ loading, msg }) {
  return <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.70)", zIndex: 900, display: loading ? "block" : "none" }}>
    <div style={{ top: "45%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute", textAlign: "center" }} >
      <Spinner />
      {msg ? <h4 style={{ marginTop: 20 }}>{msg}</h4> : null}
    </div>
  </div>
}

export function LoadingScreenMain() {
  return (
    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.70)", zIndex: 900, display: "block" }} >
      <div style={{ top: "45%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute", textAlign: "center" }}>
        <Spinner />
        <h4 style={{ marginTop: 20 }}>Aguarde, processando...</h4>
      </div>
    </div>
  );
}
