import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { LoadingContainerPadrao } from "./Base";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "#1976d2",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
    fontSize: "1rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ visible, onCancel, onOk, children, title, saving, ...props }) {
  const classes = useStyles();

  return <div>
    <Dialog fullScreen open={visible} onClose={onCancel} TransitionComponent={Transition} style={{ zIndex: "1000" }}>
      <LoadingContainerPadrao loading={saving} spinnerProps={{ marginTop: 250 }}>
        <AppBar className={classes.appBar} style={{ zIndex: 11 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {!!onOk && <Button color="inherit" onClick={onOk} disabled={saving}> {saving ? "Gravando" : "Gravar"} </Button>}
          </Toolbar>
        </AppBar>
        <div style={{ padding: 20 }}>{children}</div>
      </LoadingContainerPadrao>
    </Dialog>
  </div>
}
