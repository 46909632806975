import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { logIn, logOut, setDados, fetch } from "./actions";
import ErrorBoundary from "./components/ErrorBoundary";
import Clientes from "./containers/Clientes/Cliente";
import ClienteCad from "./containers/ClienteCad/ClienteCad";
import Contas from "./containers/ContasReceber/ContasReceber";
import PreGerarBoleto from "./containers/Boleto/PreGerarBoleto";
import GerarBoleto from "./containers/Boleto/GerarBoleto";
import PlanoContas from "./containers/PlanoContas/PlanoContas";
import QueryScreen from "./Tests/QueryScreen";
import ModalForm from "./Tests/ModalForm";
import Tools from "./containers/Outros/Tools";
import CadastroLoja from "./Tests/CadastroLoja";
import DragDropComponent from "./Tests/DragDropComponent";
import TableFixed from "./Tests/TableFixed";
import Login from "./containers/Auth/Login";
import ErrorScreen from "./components/ErrorScreen";
import Logout from "./containers/Auth/Logout";
import { LoadingScreenMain } from "./components/Diversos";

export function Main(props) {
  const [loading, setLoading] = useState(true);
  const [erroIni, setErroIni] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("un");
    const tokenId = localStorage.getItem("saat");
    const refreshToken = localStorage.getItem("srat");

    setLoading(false);

    if (tokenId) props.logIn({ user, tokenId, refreshToken });
  }, []);


  if (erroIni) return <ErrorScreen />;
  if (loading) return <LoadingScreenMain />;

  const dados = !props.auth.user ? <Switch>
    <Route component={Login} />
  </Switch>
    : <Switch>
      <Route exact path="/clientecad" component={ClienteCad} />
      <Route exact path="/planocontas" component={PlanoContas} />
      <Route exact path="/contas" component={Contas} />
      <Route exact path="/pregerar" component={PreGerarBoleto} />
      <Route exact path="/gerar" component={GerarBoleto} />

      <Route exact path="/cadloja" component={CadastroLoja} />
      <Route exact path="/sair" component={Logout} />
      <Redirect exact from="/" to="/clientecad" />
    </Switch>;

  return <ErrorBoundary>
    <Router>{dados}</Router>
  </ErrorBoundary>;
}

const mapStateToProps = state => {
  return { auth: state.auth, dados: state.dados };
};

export default connect(mapStateToProps, { logIn, logOut, fetch, setDados })(Main);
