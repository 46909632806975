import React from "react";
import { connect } from "react-redux";
import { fetchFin } from "../../actions";
import { Select, Spin, Divider } from "antd";
import debounce from "lodash/debounce";
import { LoadingOutlined } from "@ant-design/icons";
import { LabelPadrao, BtnPadrao } from "../Base";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Func extends React.Component {
  constructor(props) {
    super(props);
    this.txtCodigo = React.createRef();
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    this.listar = this.listar.bind(this);
    this.saved = this.saved.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.state = {
      data: [],
      idCadastro: null,
      fetching: false,
      enterClicked: false,
    };
  }

  setFocus() {
    this.txtCodigo.current.focus();
  }

  listar(value) {
    const fetchId = this.lastFetchId;
    let self = this;
    self.props
      .fetchFin()
      .post(`${this.props.modelo}/getlist`, { txt: value })
      .then(function (response) {
        if (fetchId !== self.lastFetchId) return;

        if (self.state.enterClicked && response.data && response.data.length === 1) {
          self.setState({ data: [], fetching: false, enterClicked: false });
          const d = response.data[0];
          self.props.onChange([{ key: d.id, label: d.codigo + " - " + d.nome }]);
        } else {
          self.setState({
            data: response.data,
            fetching: false,
            enterClicked: false,
          });
        }
      });
  }

  saved(savedData) {
    this.setState({ idCadastro: null });
    this.props.onChange([{ key: savedData.key, label: savedData.codigo + " - " + savedData.nome }]);

    if (this.props.onOk) this.props.onOk(savedData);
  }

  fetchUser = value => {
    this.lastFetchId += 1;
    this.setState({ data: [], fetching: true });
    this.listar(value);
  };

  onKeyPress = e => {
    if (e.keyCode === 13 && this.state.data.length === 0) this.setState({ enterClicked: true });
  };

  onChange = value => {
    this.setState({
      data: [],
      fetching: false,
    });

    if (!this.props.multi && value && value.length > 1) return this.props.onChange([value[value.length - 1]]);
    this.props.onChange(value);
  };

  render() {
    const { fetching, data } = this.state;
    const self = this;
    return <>
      <div onMouseDown={e => { e.preventDefault(); return false; }} style={{ position: "relative" }}>
        {this.props.showEdit && this.props.value && this.props.value.length == 1 &&
          <BtnPadrao type="link" style={{ position: "absolute", top: 0, right: 0, padding: 0, paddingBottom: 3 }} onClick={() => this.setState({ idCadastro: this.props.value[0].key })}>
            Editar
          </BtnPadrao>}

        {!!this.props.title && <LabelPadrao noMargin={this.props.noMargin}>{this.props.title}</LabelPadrao>}
        <Select
          mode="multiple"
          labelInValue
          maxTagTextLength={this.props.maxTagTextLength ? this.props.maxTagTextLength : 35}
          notFoundContent={fetching ? <Spin indicator={antIcon} size="small" /> : null}
          filterOption={false}
          {...this.props}
          ref={this.txtCodigo}
          onSearch={this.fetchUser}
          onChange={this.onChange}
          onInputKeyDown={this.onKeyPress}
          style={{ width: "100%", ...this.props.style }}
          dropdownMenuStyle={{ maxHeight: "50vh", ...this.props.dropdownMenuStyle, }}
          dropdownStyle={{ zIndex: this.props.zIndex }}
          dropdownRender={menu =>
            <div>
              {menu}
              {this.props.showAdd && <>
                <Divider style={{ margin: "4px 0", marginBottom: 0 }} />
                <div style={{ padding: "8px", textAlign: "center" }}>
                  <BtnPadrao type="link" onClick={() => self.setState({ idCadastro: "0" })} style={{ padding: 0 }}>Incluir Novo</BtnPadrao>
                </div>
              </>}
            </div>}>
          {data.map(d => <Option key={d.id} title={d.nome} style={{ color: !!d.isExcluido ? 'red' : null }}>
            {d.codigo + " - " + d.nome}
          </Option>)}
        </Select>
      </div>
    </>;
  }
}

export default connect(null, { fetchFin }, null, { forwardRef: true })(Func);

