import React from "react";
import { Button, Input, Upload, DatePicker, Empty, Modal, InputNumber, Checkbox } from "antd";
import { AiOutlineForm, AiOutlineInbox, AiOutlinePlus } from "react-icons/ai";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { CloseOutlined, EyeOutlined, FormOutlined } from "@ant-design/icons";
import Spinner from "./../assets/Spinner.gif";
import { CheckCircleOutlined, CloseCircleOutlined, PullRequestOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { Search } = Input;

export function IconCor({ cor }) {
  return <span style={{ height: '22px', width: 3, backgroundColor: cor, display: 'inline-block', marginRight: 8 }}>&nbsp;</span>;
}

const useStylesPg = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#81c2ff",
  },
  barColorPrimary: {
    backgroundColor: "#e5f0fb",
  },
})(LinearProgress);

export function InputPadrao(props) {
  const innerProps = {};
  if (!!props.tabIndex) {
    innerProps["tabindex"] = props.tabIndex;
  }

  const keyPress = e => {
    if (e.keyCode === 13) {
      if (!!props.tabIndex) {
        const inx = parseInt(props.tabIndex);
        const els = document.querySelectorAll(`[tabindex="${inx + 1}"]`);
        const el = els.length ? els[0] : document.querySelectorAll(`[tabindex="0"]`)[0];

        el.focus();
      }
    }
  };

  return <Input onKeyDown={keyPress} {...innerProps} {...props} />;
}

export function IconAtivo({ isAtivo }) {
  return isAtivo ? <CheckCircleOutlined style={{ color: '#0b0' }} /> : <CloseCircleOutlined style={{ color: '#f5222d' }} />;
}

export function IconTrocarStatus() {
  return <PullRequestOutlined />
}

export function InputNumberPadrao(props) {
  return <InputNumber {...props} />;
}

export function SearchInputPadrao(props) {
  return <Search {...props} enterButton />;
}

export function InputUploadPadrao(props) {
  return <Upload {...props}>{props.children}</Upload>;
}

export function DatePickerPadrao(props) {
  return <DatePicker format={props.picker === "month" ? "MM/YYYY" : "DD/MM/YYYY"} allowClear={false} {...props} />;
}

export function BtnPadrao(props) {
  return <Button  {...props}>{props.children}</Button>;
}

export function BtnEdit(props) {
  return <FormOutlined className="cor-primary cad-icon" {...props} style={{ ...props.style }} />;
}

export function BtnView(props) {
  return <EyeOutlined className="cor-primary cad-icon" {...props} style={{ ...props.style }} />;
}

export function ModalConfirmPadrao(title, content, okText, cancelText, onOk, onCancel) {
  function modalDelete() {
    confirm({
      title,
      content,
      okText,
      cancelText,
      onOk,
      onCancel: onCancel,
    });
  }

  return modalDelete();
}

export function BtnDeletar(props) {
  function modalDelete() {
    confirm({
      title: "Deseja deletar o registro?",
      content: "Uma vez deletado não podera ser recuperado!",
      okText: "Sim",
      cancelText: "Cancelar",
      onOk() {
        props.onOk();
      },
    });
  }

  return (
    <>
      {props.withLabel ? (
        <BtnPadrao
          {...props}
          fontSize="1.175em"
          onClick={() => {
            if (props.disableModal) {
              props.onClick();
              return;
            } else {
              modalDelete();
            }
          }}
        >
          {props.withLabel}
        </BtnPadrao>
      ) : (
        <CloseOutlined
          {...props}
          className="cor-danger cad-icon"
          onClick={() => {
            if (props.disableModal) {
              props.onClick();
              return;
            } else {
              modalDelete();
            }
          }}
        />
      )}
    </>
  );
}

export function ProgressBarPadrao(props) {
  const classes = useStylesPg();

  return (
    <div className={classes.root}>
      <ColorLinearProgress />
    </div>
  );
}

export function SemDados({ text, ...props }) {
  return (
    <div {...props} style={{ textAlign: "center", ...props.style }}>
      <AiOutlineInbox className="color-all" fontSize={75} />
      <LabelPadrao className="color-all" style={{ opacity: .8, fontWeight: "initial" }} noMargin>Sem dados</LabelPadrao>
    </div>
  );
}
export function ModalPadrao(props) {
  return <Modal {...props}>{props.children}</Modal>;
}

export function LabelPadrao(props) {
  return (
    <label
      {...props}
      style={{
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.65)",
        marginTop: !props.noMargin && 15,
        display: "block",
        ...props.style,
      }}
    >
      {props.children}
    </label>
  );
}

export function SwitchBicolorPadrao(props) {
  const ModifiedSwitch = withStyles({
    switchBase: {
      color: "rgb(220, 0, 78)",
    },
    track: {
      backgroundColor: "rgb(220, 0, 78)",
    },
  })(Switch);

  return <ModifiedSwitch color="primary" {...props} />;
}

export function CheckBoxPadrao(props) {
  return <Checkbox  {...props}>{props.children}</Checkbox>;
}

export function SpeedDialPadrao(props) {
  return (
    <>
      <SpeedDial ariaLabel="SpeedDial example" open={props.open} icon={<SpeedDialIcon />} {...props}>
        {props.ActionList.map((x, inx) => (
          <SpeedDialAction tooltipTitle={x.title} key={inx} icon={x.icon} {...x} />
        ))}
      </SpeedDial>
    </>
  );
}

export function CardPadrao(props) {
  return (
    <div {...props} style={{ display: "flex", border: "1px solid #ddd", borderTopRightRadius: 10, borderBottomRightRadius: 10, cursor: 'pointer', ...props.style }}>
      <div style={{ width: 15, height: "100%", background: props.color }} />
      <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative" }} >
        <LabelPadrao>{props.title}</LabelPadrao>
        <h2 style={{ color: props.color }}>{props.value}</h2>
      </div>
    </div>
  );
}

export function LoadingContainerPadrao({ loading, spinnerProps, ...props }) {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ opacity: loading ? 0.5 : 1 }}>{props.children}</div>
      <div style={{ position: "absolute", width: "100%", textAlign: "center", top: "0", padding: 25, display: loading ? "block" : "none" }}>
        {!props.disableSpinner && <img src={Spinner} alt="spinner" style={{ fontSize: 25, color: "#1976d2", ...spinnerProps }} />}
      </div>
    </div>
  );
}


export function BtnAdd(props) {
  return (
    <Button
      shape="circle"
      type="primary"
      {...props}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 0px 10px #bfbfbf",
        fontSize: 30,
        fontWeight: "lighter",
        ...props.style,
      }}
    >
      <p style={{ marginTop: 25 }}>+</p>
    </Button>
  );
}

export function Header({ title, onClick, onSearch, onChange }) {
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "50%" }}>
        <h3>{title}</h3>
        <SearchInputPadrao
          onSearch={value => onSearch(value)}
          onChange={onChange}
          placeholder="Pesquisar"
        />
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <BtnAdd onClick={onClick} />
        </div>
      </div>
    </div>
  );
}
