import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { BtnEdit, BtnDeletar, SemDados, BtnView } from "./Base";
import { Checkbox } from "antd";
import formatarData from "../Utils/formatarData";

const culture = new Intl.NumberFormat("pt-BR", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const cultureN0 = new Intl.NumberFormat("pt-BR", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function getAlign(column) {
  if (column.tipo === "rs" || column.tipo === "int" || column.tipo === "dec" || column.tipo === "right") return "right";
  else if (column.tipo === 'center') return "center";

  return "left";
}

function getValue(cell, column) {
  if (column.tipo === "rs" && cell.value != undefined) return `R$ ${culture.format(cell.value)}`;
  else if (column.tipo === "dec" && cell.value != undefined) return culture.format(cell.value);
  else if (column.tipo === "int" && cell.value != undefined) return cultureN0.format(cell.value);
  else if (column.tipo === "dt" && cell.value != undefined) return formatarData(cell.value).DATA;
  else if (column.tipo === "dthr" && cell.value != undefined) return formatarData(cell.value).DATAHORASEGUNDOS;
  else if (column.tipo === "cpfCnpj" && cell.value != undefined) return cell.value.length == 11 ? cell.value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cell.value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return cell.render("Cell");
}

export default function TabelaPadrao(props) {
  if (!props.hideSemDados && (!props.data || !props.data.length)) return <SemDados style={{ marginTop: 50 }} />;
  return <TableDet {...props} />;
}

function TableDet({ columns, data, setEdit, setView, onRowClick, onDelete, setMarcados: setMarca, ghost, showChk, checkOnlyOne, branca, ...rest }) {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({ columns, data, }, useSortBy);
  const { marcados, marcar, indeterminate, allChecked, checkAll } = useChk(data, checkOnlyOne);
  const hasFooter = React.useMemo(() => columns.filter(x => !!x.Footer).length > 0, [columns]);
  const hasChk = React.useMemo(() => !!setMarca, [setMarca]);

  useEffect(() => { if (!!setMarca) setMarca(marcados); }, [marcados]);

  return <table className={ghost ? "tabela-padrao-branca" : "tabela-padrao"} {...rest} {...getTableProps()}>
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {hasChk && <th style={{ textAlign: "left" }}>
            {!checkOnlyOne && <Checkbox indeterminate={indeterminate} onChange={checkAll} checked={allChecked} />}
          </th>}
          {headerGroup.headers.map((column, inxC) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ cursor: "pointer", textAlign: getAlign(columns[inxC]), }}>
              {column.render("Header")}
            </th>
          ))}
          <th></th>
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return <Row key={i} hasChk={hasChk} row={row} columns={columns} marcados={marcados} inx={i} marcar={marcar} setEdit={setEdit} setView={setView} onRowClick={onRowClick} onDelete={onDelete} />;
      })}
    </tbody>
    <tfoot>
      {hasFooter &&
        footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {hasChk && <td></td>}
            {group.headers.map((column, inxC) => (
              <td {...column.getFooterProps()} style={{ textAlign: getAlign(columns[inxC]) }}>
                <span>
                  {(columns[inxC].Somar && `‏‏‎R$ ${culture.format(rows.reduce((sum, row) => row.values[columns[inxC].accessor] + sum, 0))}`) || (columns[inxC].SomarQtd && `‏‏${culture.format(rows.reduce((sum, row) => row.values[columns[inxC].accessor] + sum, 0))}`) || column.render("Footer")}
                </span>
              </td>
            ))}
            <td></td>
          </tr>
        ))}
    </tfoot>
  </table>;
}

const Row = React.memo(({ hasChk, row, columns, marcados, marcar, setEdit, setView, onRowClick, onDelete, inx }) => {
  return (
    <tr onClick={() => !!onRowClick && onRowClick(row.original)} {...row.getRowProps()}>
      {hasChk && <td>
        <Checkbox checked={marcados.includes(row.original.id)} onChange={e => marcar(row.original.id, e.target.checked)} />
      </td>}
      {row.cells.map((cell, inx) => {
        const c = columns[inx];
        return (
          <td {...cell.getCellProps()} style={{ textAlign: getAlign(c) }}>
            {getValue(cell, c)}
          </td>
        );
      })}
      <td className="td-icon" style={{ textAlign: "right" }}>
        {setView && <BtnView data-cy={'table-btn-edit-' + inx} onClick={() => setView(row.original)} />}
        {setEdit && <BtnEdit data-cy={'table-btn-edit-' + inx} onClick={() => setEdit(row.original)} />}
        {onDelete && <BtnDeletar onOk={() => onDelete(row.original)} />}
      </td>
    </tr>
  );
});

function useChk(data, checkOnlyOne) {
  const [marcados, setMarcados] = useState([]);

  const [allChecked, indeterminate] = React.useMemo(() => {
    if (!marcados || marcados.length === 0) return [false, false];
    if (marcados.length >= data.length) return [true, false];
    else return [false, true];
  }, [marcados]);

  const marcar = React.useCallback(
    (id, checked) => {
      if (checkOnlyOne) setMarcados([]);
      setMarcados(marcados => {
        if (!checked) return marcados.filter(x => x != id);
        else return [...marcados, id];
      });
    },
    [setMarcados]
  );

  useEffect(() => setMarcados([]), data);

  const checkAll = React.useCallback(() => { setMarcados(!allChecked ? data?.map(x => x.id) : []); }, [allChecked, data, setMarcados]);

  return { marcados, marcar, indeterminate, allChecked, checkAll };
}
