import React, { useState } from "react";
import { Divider, Form, Radio, Tabs, Input, Checkbox } from "antd";
import EnderecoForm from "./EnderecoForm";
import PessoaForm from "./PessoaForm";
import { BtnPadrao, InputNumberPadrao, LabelPadrao } from "../../components/Base";
import { InputNumero } from "../../components/InputNumero";

const { TabPane } = Tabs;

export default function ClienteDetalhe({ cliente, setCliente, ...props }) {
  const [activeKey, setActiveKey] = useState('1');
  const [form] = Form.useForm();

  function salvar() {
    if (!cliente.razaoSocial || !cliente.nome || !cliente.cnpj) {
      setActiveKey('1');
      setTimeout(() => {
        form.validateFields();
      }, 500);
    } else if (!cliente.cep || !cliente.logradouro || !cliente.numero || !cliente.bairro || !cliente.cidade || !cliente.uf) {
      setActiveKey('2');
      setTimeout(() => {
        form.validateFields();
      }, 500);
    }
    else props.onOk();
  }

  return <div style={{ maxWidth: 1000, margin: "0 auto" }}>
    <Form form={form} onFinishFailed={() => salvar(true)} onKeyDown={e => e.key === "Enter" && e.target.type !== 'textarea' && e.preventDefault()} initialValues={cliente} onFinish={salvar}>
      <Tabs activeKey={activeKey} onChange={v => setActiveKey(v)}>
        <TabPane tab="Dados cliente" key="1">
          <PessoaForm object={cliente} setObject={setCliente} />
          <LabelPadrao>Banco</LabelPadrao>
          <Radio.Group style={{ display: "flex" }} value={cliente.banco} onChange={v => setCliente({ ...cliente, banco: v.target.value })}>
            <Radio value="itau">Itaú</Radio>
            <Radio value="santander">Santander</Radio>
            <Radio value="asaas">Asaas</Radio>
          </Radio.Group>

          <DadosCobrancaCliente dados={cliente} setDados={setCliente} />
        </TabPane>
        <TabPane tab="Endereço" key="2">
          <EnderecoForm required setFormValue={v => form.setFieldsValue({ ...cliente, ...v })} object={cliente} setObject={endereco => setCliente({ ...cliente, ...endereco })} />
        </TabPane>
        <TabPane tab="Configurações" key="4">
          {/* <Checkbox checked={!cliente.isExcluido} onChange={e => setCliente({ ...cliente, isExcluido: !cliente.isExcluido })}>Conta Ativa</Checkbox> */}
        </TabPane>
        <TabPane tab="Observações" key="3">
          <div style={{ display: "flex", height: "100%" }}>
            {/* <LabelPadrao>Observação</LabelPadrao> */}
            <Input.TextArea value={cliente.observacao} onChange={e => setCliente({ ...cliente, observacao: e.target.value })} rows={20} />
          </div>
        </TabPane>
      </Tabs>
      <BtnPadrao id="btnFinalizarHide" htmlType="submit" style={{ display: 'none' }} />
    </Form>
  </div>;
}


export function DadosCobrancaCliente({ dados, setDados, disabled }) {
  return <div style={{ width: "100%", marginBottom: 25 }}>
    <Divider orientation="left">Dados Cobrança</Divider>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <LabelPadrao>Quantidade de lojas</LabelPadrao>
        <InputNumberPadrao disabled={disabled} style={{ width: 135 }} value={dados.qtdLojas} onChange={v => setDados({ ...dados, qtdLojas: !!v ? v : 0 })} />
      </div>
      <div style={{ margin: "0 10px" }}>
        <LabelPadrao>Valor por loja</LabelPadrao>
        <InputNumero disabled={disabled} style={{ width: 135 }} value={dados.valorLoja} onChange={v => setDados({ ...dados, valorLoja: !!v ? v : 0 })} />
      </div>
      <div>
        <LabelPadrao>Tipo de cobrança</LabelPadrao>
        <Radio.Group disabled={disabled} style={{ display: "flex" }} value={dados.tipoCobranca} onChange={v => setDados({ ...dados, tipoCobranca: v.target.value })}>
          <Radio value="mensal">Mensal</Radio>
          <Radio value="trimestral">Trimestral</Radio>
          <Radio value="semestral">Semestral</Radio>
          <Radio value="anual">Anual</Radio>
        </Radio.Group>
      </div>
      <div style={{ marginLeft: 10 }}>
        <LabelPadrao>Dia do faturamento</LabelPadrao>
        <Radio.Group disabled={disabled} style={{ display: "flex" }} value={dados.diaFaturamento} onChange={v => setDados({ ...dados, diaFaturamento: v.target.value })}>
          <Radio value={10}>10</Radio>
          <Radio value={20}>20</Radio>
          <Radio value={30}>30</Radio>
        </Radio.Group>
      </div>
    </div>
  </div>;
}