import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={pt_BR}>
      <App />
    </ConfigProvider >
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.register();
