import React, { useState } from "react";
import Mensagens from "../../components/Mensagens";
import moment from "moment";

export function useFetchForm(api, initialData, config) {

  const formatter = oldData => {
    let d = { ...oldData };
    if (d.dtEmissao) d.dtEmissao = moment(d.dtEmissao);
    if (d.dtVencimento) d.dtVencimento = moment(d.dtVencimento);
    if (d.dtCompetencia) d.dtCompetencia = moment(d.dtCompetencia);
    if (d.data) d.data = moment(d.data);

    return d;
  };

  return useFetch(api, initialData, { formatter });
}

export function useFetch(api, initialData, { onRejected, formatter } = {}, cb) {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(initialData);

  const listar = (_api, setLoaded) => {
    if (isLoading) return;

    setIsLoading(true);

    (_api instanceof Function ? _api : api)().then(function (response) {
      setIsLoading(false);

      let dados = response.data;
      if (formatter) dados = formatter(dados);

      if (setLoaded instanceof Function) setLoaded(dados);
      else setFetchedData(dados);

      if (cb) cb(dados);
    }).catch(function (error) {
      setIsLoading(false);
      setFetchedData(initialData);

      const msg = error && error.response && error.response.status === 400 && error.response.data ? error.response.data : "Falha na conexão";
      if (onRejected) onRejected(msg);
      else Mensagens.showErro(msg);
    });
  };
  return [fetchedData, listar, isLoading, setFetchedData];
}

export function useSave(api, onSucess, { onRejected } = {}) {
  const [isSaving, setIsSaving] = useState(false);

  const save = (_api, _onSucess) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    (_api instanceof Function ? _api : api)()
      .then(function (response) {
        setIsSaving(false);
        if (_onSucess) _onSucess(response.data);
        else if (onSucess) onSucess(response.data);
        else Mensagens.showSucesso("Gravado com sucesso!");
      })
      .catch(function (error) {
        setIsSaving(false);

        if (error && error.response && error.response.status === 403) return;

        const msg = error && error.response && error.response.status === 400 && (error.response.data && typeof error.response.data == 'string') ? error.response.data : "Falha na conexão";

        if (onRejected) onRejected(msg);
        else Mensagens.showErro(msg);
      });
  };

  return [save, isSaving];
}
