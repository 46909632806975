import React, { useRef } from "react";
import BuscarCep from "./BuscarCep";
import { Form } from "antd";
import { InputPadrao, LabelPadrao } from "../../components/Base";

export default function EnderecoForm({ object, setObject, setFormValue, required, disableForm, onOk }) {
  const numeroRef = useRef("");
  const complementoRef = useRef("");
  return <div>
    <div style={{ display: "flex", alignItems: "flex-end" }}>
      <div>
        <LabelPadrao>CEP</LabelPadrao>
        <BuscarCep
          disableForm={disableForm}
          cep={object.cep}
          setCep={e => setObject({ ...object, cep: e })}
          required={required}
          onOk={endereco => {
            if (endereco) {
              var end = {
                bairro: endereco.bairro,
                logradouro: endereco.logradouro,
                uf: endereco.uf,
                ibge: endereco.ibge,
                cidade: endereco.localidade,
                cep: endereco.cep,
              }
              setObject(end);
              setFormValue && setFormValue(end);
            }
          }} />
      </div>
    </div>
    <div>
      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ width: "45%" }}>
          <LabelPadrao>Logradouro</LabelPadrao>
          {!disableForm ? <Form.Item name="logradouro" rules={[{ type: "string", message: "Insira o logradouro", required: required, whitespace: true, }]}>
            <InputPadrao placeholder="Insira o logradouro" maxLength={60} onChange={e => setObject({ ...object, logradouro: e.target.value })} tabIndex={10} />
          </Form.Item> :
            <InputPadrao placeholder="Insira o logradouro" maxLength={60} value={object.logradouro} onChange={e => setObject({ ...object, logradouro: e.target.value })} tabIndex={10} />}
        </div>
        <div style={{ width: "10%", margin: "0 10px" }}>
          <LabelPadrao>Número</LabelPadrao>
          {!disableForm ? <Form.Item name="numero" rules={[{ type: "string", message: "Insira o número", required: required, whitespace: true, }]}>
            <InputPadrao
              ref={numeroRef}
              onPressEnter={() => complementoRef.current.focus()}
              placeholder="Número"
              data-cy="input-numero"
              maxLength={60}
              onChange={e => setObject({ ...object, numero: e.target.value })}
              tabIndex={11}
            />
          </Form.Item> :
            <InputPadrao
              onPressEnter={() => complementoRef.current.focus()}
              ref={numeroRef}
              placeholder="Número"
              data-cy="input-numero"
              maxLength={60}
              onChange={e => setObject({ ...object, numero: e.target.value })}
              tabIndex={11}
            />}
        </div>
        <div style={{ width: "45%" }}>
          <LabelPadrao>Complemento</LabelPadrao>
          <InputPadrao
            placeholder="Insira o complemento"
            data-cy="input-complemento"
            maxLength={60}
            style={{ width: "100%" }}
            value={object.complemento}
            onChange={e => setObject({ ...object, complemento: e.target.value })}
            tabIndex={12}
            onPressEnter={() => !!onOk && onOk()}
            ref={complementoRef}
          />
        </div>
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "25%" }}>
            <LabelPadrao>Bairro</LabelPadrao>
            {!disableForm ? <Form.Item name="bairro" rules={[{ type: "string", message: "Insira o bairro", required: required, whitespace: true, }]}>
              <InputPadrao placeholder="Insira o bairro" maxLength={60} onChange={e => setObject({ ...object, bairro: e.target.value })} tabIndex={13} />
            </Form.Item> :
              <InputPadrao placeholder="Insira o bairro" maxLength={60} value={object.bairro} onChange={e => setObject({ ...object, bairro: e.target.value })} tabIndex={13} />}
          </div>
          <div style={{ margin: "0px 10px", width: "55%" }}>
            <LabelPadrao>Cidade</LabelPadrao>
            {!disableForm ? <Form.Item name="cidade" rules={[{ type: "string", message: "Insira a cidade", required: required, whitespace: true, }]}>
              <InputPadrao placeholder="Insira a cidade" maxLength={60} onChange={e => setObject({ ...object, cidade: e.target.value })} tabIndex={14} />
            </Form.Item> :
              <InputPadrao placeholder="Insira a cidade" maxLength={60} value={object.cidade} onChange={e => setObject({ ...object, cidade: e.target.value })} tabIndex={14} />}
          </div>
          <div style={{ width: "5%" }}>
            <LabelPadrao>UF</LabelPadrao>
            {!disableForm ? <Form.Item name="uf" rules={[{ type: "string", message: "UF", required: required, whitespace: true, }]}>
              <InputPadrao placeholder="UF" maxLength={2} onChange={e => setObject({ ...object, uf: e.target.value })} tabIndex={15} />
            </Form.Item> :
              <InputPadrao placeholder="UF" maxLength={2} value={object.uf} onChange={e => setObject({ ...object, uf: e.target.value })} tabIndex={15} />}
          </div>
          <div style={{ marginLeft: 10, width: "15%" }}>
            <LabelPadrao>IBGE</LabelPadrao>
            {!disableForm ? <Form.Item name="ibge" rules={[{ type: "string", message: "Insira o IBGE", required: false, whitespace: true, }]}>
              <InputPadrao placeholder="Insira o IBGE" onChange={e => setObject({ ...object, ibge: e.target.value })} tabIndex={16} />
            </Form.Item> :
              <InputPadrao value={object.ibge} nChange={e => setObject({ ...object, ibge: e.target.value })} tabIndex={16} />}
          </div>
        </div>
      </div>
    </div>
  </div>
}
