import React from "react";
import { InputNumber } from "antd";

export function InputNumero({ onChange, value, size, showCurrency = true, ...props }) {

  return <InputNumber
    className={showCurrency && "input-currency"}
    precision={2}
    decimalSeparator=","
    value={value}
    onChange={onChange}
    size={size ? size : "default"}
    style={{ width: "100%", ...props.style }}
    {...props}
  />
}
