import React, { Component } from "react";
import { connect } from "react-redux";
import { logOut } from "../../actions";
import { Redirect } from "react-router-dom";

class Logout extends Component {
  componentDidMount() {
    this.props.logOut();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default connect(null, { logOut })(Logout);
