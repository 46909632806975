import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { fetchTest } from '../actions'
import { useFetch, useSave } from "../components/Hooks/Conexao";
import Container from "../components/Container";
import { BtnAdd, BtnDeletar, BtnEdit, BtnPadrao, DatePickerPadrao, InputNumberPadrao, InputPadrao, LabelPadrao, ModalPadrao, SemDados } from "../components/Base";
import { InputNumero } from "../components/InputNumero";
import { Select } from "antd";
import moment from "moment";
import culture from "../Utils/Culture";
import formatarData from "../Utils/formatarData";

function CadastroLoja(props) {
    const [detalhe, setDetalhe] = useState(null);
    const [filtros, setFiltros] = useState({ periodo: moment(), lojaId: undefined });
    const [dados, listar, isLoading, setDados] = useFetch(() => props.fetchTest().post("loja/getlist", filtros));
    const [save, saving] = useSave(() => props.fetchTest().post("loja/save", dados), () => listar());

    function saveDetalhe() {
        var aux = [...dados];
        var d = aux.findIndex(x => x.lojaId === detalhe.lojaId);
        if (d != -1) aux[d] = detalhe;
        else aux.push(detalhe);
        setDados(aux);
        setDetalhe(null);
    }

    useEffect(() => listar(), []);

    return <Container loading={isLoading || saving}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: 250 }}>
                <LabelPadrao>Selecione a loja</LabelPadrao>
                <Select allowClear placeholder="Selecionar loja" style={{ width: "100%" }} value={filtros.lojaId} onChange={v => setFiltros({ ...filtros, lojaId: v })}>
                    <Select.Option key="1" value={1}>Loja 1</Select.Option>
                    <Select.Option key="2" value={2}>Loja 2</Select.Option>
                    <Select.Option key="3" value={3}>Loja 3</Select.Option>
                </Select>
            </div>
            <div style={{ margin: "0 10px" }}>
                <LabelPadrao>Selecione o período</LabelPadrao>
                <DatePickerPadrao picker="month" value={filtros.periodo} onChange={v => setFiltros({ ...filtros, periodo: v })} />
            </div>
            <BtnPadrao onClick={listar} type="primary" style={{ marginRight: 10 }}>Listar</BtnPadrao>
            <BtnPadrao onClick={save} type="primary" ghost>Gravar</BtnPadrao>
        </div>
        <BtnAdd style={{ marginTop: 25 }} onClick={() => setDetalhe({ lojaId: -Math.abs(dados.length ? dados[dados.length - 1].lojaId + 1 : 0), dtEmissao: filtros.periodo })} />

        {!!(dados || []).length ? <table className="tabela-padrao" style={{ marginTop: 25 }}>
            <thead>
                <tr>
                    <th>Data Emissão</th>
                    <th>Fornecedor</th>
                    <th style={{ textAlign: "right" }}>Empresa Entrada</th>
                    <th style={{ textAlign: "right" }}>Valor Total</th>
                    <th style={{ textAlign: "right" }}>Número</th>
                    <th style={{ textAlign: "right" }}></th>
                </tr>
            </thead>
            <tbody>
                {((dados || []).filter(x => !x.isExcluido) || []).map((x, inx) => <tr key={inx}>
                    <td> {formatarData(x.dtEmissao).DATA}</td>
                    <td>{x.fornecedor}</td>
                    <td style={{ textAlign: "right" }}>R${culture.format(x.entrada)}</td>
                    <td style={{ textAlign: "right" }}>R${culture.format(x.valorTotal)}</td>
                    <td style={{ textAlign: "right" }}>{x.numero}</td>
                    <td style={{ textAlign: "right" }}><BtnEdit onClick={() => setDetalhe({ ...x })} /> <BtnDeletar onOk={() => {
                        const aux = [...dados];
                        aux.find(s => s.lojaId == x.lojaId).isExcluido = true;
                        setDados(aux);
                    }} /></td>
                </tr>)}
            </tbody>
        </table> : <SemDados />}

        <ModalPadrao visible={detalhe} onCancel={() => setDetalhe(null)} width={800} footer={<><BtnPadrao type="primary" onClick={saveDetalhe}>Gravar</BtnPadrao> <BtnPadrao type="primary" ghost onClick={() => setDetalhe(null)}>Sair</BtnPadrao></>}>
            {detalhe && <>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "33%" }}>
                        <LabelPadrao>Data emissão</LabelPadrao>
                        <DatePickerPadrao disabledDate={d => !d || d.isBefore(filtros.periodo)} style={{ width: "100%" }} picker="month" value={moment(detalhe.dtEmissao)} onChange={v => setDetalhe({ ...detalhe, dtEmissao: v })} />
                    </div>
                    <div style={{ margin: "0 10px", width: "33%" }}>
                        <LabelPadrao>Fornecedor</LabelPadrao>
                        <Select placeholder="Selecionar fornecedor" style={{ width: "100%" }} value={detalhe.fornecedor} onChange={v => setDetalhe({ ...detalhe, fornecedor: v })}>
                            <Select.Option key="1" value="fornecedor 1">Fornecedor 1</Select.Option>
                            <Select.Option key="2" value="fornecedor 2">Fornecedor 2</Select.Option>
                            <Select.Option key="3" value="fornecedor 3">Fornecedor 3</Select.Option>
                        </Select>
                    </div>
                    <div style={{ width: "33%" }}>
                        <LabelPadrao>Empresa NFe</LabelPadrao>
                        <InputPadrao placeholder="Insira a NFe" value={detalhe.nfe} onChange={v => setDetalhe({ ...detalhe, nfe: v.target.value })} />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "33%" }}>
                        <LabelPadrao>Empresa entrada</LabelPadrao>
                        <InputNumero style={{ width: "100%" }} value={detalhe.entrada} onChange={v => setDetalhe({ ...detalhe, entrada: v })} />
                    </div>
                    <div style={{ margin: "0 10px", width: "33%" }}>
                        <LabelPadrao>Valor total</LabelPadrao>
                        <InputNumero style={{ width: "100%" }} value={detalhe.valorTotal} onChange={v => setDetalhe({ ...detalhe, valorTotal: v })} />
                    </div>
                    <div style={{ width: "33%" }}>
                        <LabelPadrao>Número</LabelPadrao>
                        <InputNumberPadrao placeholder="Insira o número" style={{ width: "100%" }} value={detalhe.numero} onChange={v => setDetalhe({ ...detalhe, numero: v })} />
                    </div>
                </div>
            </>}
        </ModalPadrao>
    </Container>
}


export default connect(null, { fetchTest })(CadastroLoja);