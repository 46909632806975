import axios from "axios";

const serverUrl = process.env.NODE_ENV === 'development' && true ? process.env.REACT_APP_SYSV2_API : 'https://financeirofunc.azurewebsites.net/api';
const serverFinUrl = process.env.NODE_ENV === 'development' && true ? process.env.REACT_APP_SYSFIN_API : 'https://financeirofunc.azurewebsites.net/api';
const serverAgreg = process.env.NODE_ENV === 'development' && true ? process.env.REACT_APP_AGREGADOR_API : 'https://financeirofunc.azurewebsites.net/api';

export const logIn = (dados) => {
  localStorage.setItem("un", dados.user);
  localStorage.setItem("saat", dados.tokenId);
  localStorage.setItem("srat", dados.refreshToken);

  return { type: "LOGIN", dados };
};

export const logOut = () => {
  localStorage.clear();
  return { type: "LOGOUT" };
};

export const setDados = (dados) => {
  return { type: "SETDADOS", dados };
};

export const setDadosPesq = (name, dados) => {
  return { type: "SETDADOSPESQ", name, dados };
};

export const fetchNoAuth = () => {
  return (dispatch) => {
    return axios.create({
      baseURL: serverUrl,
    });
  };
};

export const fetch = () => {
  return (dispatch) => {
    return axios.create({
      baseURL: serverUrl,
    });
  };
};

export const fetchAgreg = () => {
  return (dispatch) => {
    return axios.create({
      baseURL: serverAgreg,
    });
  };
};

export const fetchFin = () => {
  return (dispatch) => {
    return axios.create({
      baseURL: serverFinUrl,
    });
  };
};

export const fetchTest = () => {
  return (dispatch) => {
    return axios.create({
      baseURL: "http://localhost:5010/api",
    });
  };
};

