import React, { useState } from "react";
import { Select, Divider, } from "antd";

export default function SelectBasico({ dados, setDados, getItem, addButton, ...rest }) {
  const [, setCadId] = useState(null);
  return (
    <>
      <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
        <Select
          showSearch
          data-cy={rest.dataCy}
          allowClear={rest.allowClear}
          {...rest}
          style={{ width: "100%", ...rest.style }}
          optionFilterProp="children"
          onClick={e => e.target.focus()}
          filterOption={(input, option) => {
            if (!option.children) return false;
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          dropdownStyle={{ zIndex: rest.zIndex }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              {addButton && (
                <>
                  <Divider style={{ margin: "4px 0", marginBottom: 0 }} />
                  <div style={{ padding: "8px", textAlign: "center" }}>
                    <button
                      className="btn btn-link"
                      onClick={() => setCadId(0)}
                      style={{ padding: 0 }}
                    >
                      Incluir Novo
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        >
          {rest.multiNivel ? (<>
            {(dados || []).map((pai) => (
              <Select.OptGroup key={pai.id} label={pai.nome}>
                {(pai.filhos || []).map((item) => (
                  <Select.Option title={getItem ? getItem(item) : item.nome} key={item.id} value={item.id}>
                    {getItem ? getItem(item) : item.nome}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </>)
            :
            (<>
              {(dados || []).map((item, inx) => (
                <Select.Option title={getItem ? getItem(item) : item.nome} key={item.id} value={item.id} data-cy={rest.dataCy + "-item-" + inx}>
                  {getItem ? getItem(item) : item.sigla ? `${item.sigla} | ${item.nome}` : item.nome}
                </Select.Option>
              ))}
            </>)
          }
        </Select>
      </div>
    </>
  );
}
