import { Divider, Radio, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { logIn, fetchFin } from "../../actions";
import { BtnPadrao, DatePickerPadrao, InputPadrao, LabelPadrao, ModalPadrao } from "../../components/Base";
import Container from "../../components/Container";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import TabelaPadrao from "../../components/TabelaPadrao";
import toLocale from "../../Utils/toLocale";

const { Paragraph } = Typography;
const INITIAL_STATE = { dtIni: moment(), dtFim: moment(), gerado: true, banco: "itau" };

function PreGerarBoleto({ tipo, ...props }) {
    const [filtros, setFiltros] = useState(INITIAL_STATE);
    const [marcados, setMarcados] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [dados, listarDados, isLoading, setDados] = useFetch(() => props.fetchFin().post("contas/getlist", filtros));
    const [descricao, setDescricao] = useState(null);
    const [gerar, gerando] = useSave(() => props.fetchFin().post("boleto/pregerar", { Contas: dados.filter(x => marcados.includes(x.id)), descricao }), () => { listarDados(); setModalVisible(false); setMarcados([]); });

    const totais = useMemo(() => {
        if (!dados) return {};
        var lista = [...dados];
        if (marcados.length) lista = lista.filter(x => marcados.includes(x.id));
        return { total: lista.length, valor: lista.reduce((a, b) => a + b.valor, 0) };
    }, [dados, marcados]);

    useEffect(() => { if (!modalVisible) setDescricao(null); }, [modalVisible]);
    useEffect(() => listarDados(), []);

    const columns = [
        {
            Header: "Nome",
            accessor: "nome",
            Footer: () => <span>({totais.total})</span>
        },
        {
            Header: "Emissão",
            accessor: "dataEmissao",
            tipo: "dt",
        },
        {
            Header: "Dup",
            accessor: "numero",
        },
        {
            Header: "Vencimento",
            accessor: "dataVencimento",
            tipo: "dt",
        },
        {
            Header: "Observação",
            accessor: "obs",
            Cell: p => {
                return <div style={{ maxWidth: 150 }}> <Paragraph editable={{
                    onChange: v => {
                        const aux = [...dados];
                        aux[aux.findIndex(x => x.id === p.row.original.id)].obs = v;
                        setDados(aux);
                    }
                }}>{p.row.original.obs}</Paragraph></div>;
            },
        },
        {
            Header: "Banco",
            accessor: "banco",
            tipo: "right",
        },
        {
            Header: "Valor",
            accessor: "valor",
            tipo: "rs",
            Footer: () => <span>{toLocale(totais.valor)}</span>
        }
    ];

    return <Container loading={isLoading || gerando}>
        <Divider orientation="left">Pré-Gerar Boleto</Divider>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>
                <LabelPadrao>Banco</LabelPadrao>
                <Radio.Group style={{ display: "flex" }} value={filtros.banco} onChange={v => setFiltros({ ...filtros, banco: v.target.value })}>
                    <Radio value="itau">Itaú</Radio>
                    <Radio value="santander">Santander</Radio>
                    <Radio value="asaas">Asaas</Radio>
                </Radio.Group>
            </div>
            <div style={{ margin: "0 10px" }}>
                <div><LabelPadrao>Período</LabelPadrao></div>
                <DatePickerPadrao value={filtros.dtIni} onChange={d => setFiltros({ ...filtros, dtIni: d })} />
            </div>
            <DatePickerPadrao value={filtros.dtFim} onChange={d => setFiltros({ ...filtros, dtFim: d })} />
            <BtnPadrao style={{ marginLeft: 10 }} type="primary" ghost onClick={listarDados}>Listar</BtnPadrao>
            <div style={{ flex: 2, textAlign: 'right' }}>
                <BtnPadrao style={{ marginTop: 10 }} disabled={!marcados.length} type="primary" onClick={() => setModalVisible(true)}>Gerar</BtnPadrao>
            </div>
        </div>

        {dados && <TabelaPadrao columns={columns} style={{ marginTop: 25 }} data={dados} setMarcados={m => setMarcados(m)} />}
        <ModalPadrao visible={modalVisible} title="Pré-Gerar Boletos" onCancel={() => setModalVisible(false)} footer={<>
            <BtnPadrao loading={gerando} type="primary" onClick={gerar}>Confirmar</BtnPadrao>
            <BtnPadrao disabled={gerando} type="primary" ghost onClick={() => setModalVisible(false)}>Cancelar</BtnPadrao>
        </>}>
            <LabelPadrao noMargin>Descrição</LabelPadrao>
            <InputPadrao placeholder="Insira a descrição" value={descricao} onChange={(v) => setDescricao(v.target.value)} />
        </ModalPadrao>
    </Container>;
}

export default connect(null, { logIn, fetchFin })(PreGerarBoleto);




