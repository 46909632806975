import { Collapse, Divider, Input, Tag, Form } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchFin, logIn } from "../../actions";
import { BtnPadrao, CheckBoxPadrao, DatePickerPadrao, LabelPadrao, LoadingContainerPadrao, ModalPadrao } from "../../components/Base";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import { InputNumero } from "../../components/InputNumero";
import mensagens from "../../components/Mensagens";
import SelectFiltros from "../../components/Selects/SelectFiltros";
import SelectPesq from "../../components/Selects/SelectPesq";
import { DadosCobrancaCliente } from "../ClienteCad/ClienteDetalhe";
const { Panel } = Collapse;

function ContasDetalhe({ detalhe, onCancel, visible, ...props }) {
  const [form] = Form.useForm();
  const [dados, listar, isLoading, setDados] = useFetch(() => props.fetchFin().get(`contas/${detalhe}`), {}, {}, v => form.setFieldsValue(v));
  const [salvar, isSaving] = useSave(() => props.fetchFin().post("contas/inserir", dados), () => props.onOk());
  const [dadosFin, listarDadosFin, isLoadingDadosFin, setDadosFin] = useFetch();

  useEffect(() => {
    if (detalhe) {
      if (dados?.repetir) setDados({ ...dados, periodicidade: "mensal" });
      else setDados({ ...dados, periodicidade: null });
    }
  }, [dados?.repetir]);

  useEffect(() => {
    if (visible) listar();
    else setDadosFin(null);
  }, [visible]);

  useEffect(() => {
    if (visible && !!dados?.cliente[0]?.value && !dadosFin) listarDadosFin(() => props.fetchFin().get(`/cliente/${dados?.cliente[0]?.value}`));
  }, [dados]);

  useEffect(() => {
    if (!!dadosFin) {
      const valorAtu = !dados.valor ? dadosFin.valorTotal : dados.valor;
      form.setFieldsValue({ ...dados, valor: valorAtu });
      setDados({ ...dados, valor: valorAtu });
    }
  }, [dadosFin]);

  return <ModalPadrao footer={null} visible={visible} width={900} title="Contas a receber" onCancel={onCancel}>
    <LoadingContainerPadrao loading={isLoading || isLoadingDadosFin} spinnerProps={{ fontSize: 50, marginTop: 200 }}>
      {!!detalhe && <Form form={form} onKeyPress={e => e.key === "Enter" && e.preventDefault()} initialValues={dados} onFinish={salvar}>
        <div {...props}>
          {dados.dataPagamento && <Tag color="warning" style={{ width: "100%", padding: 5, textAlign: "center", margin: "10px 0", fontSize: 15 }}>Baixa efetuada</Tag>}
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: 6 }}>
              <Form.Item name="cliente" rules={[{ type: "array", message: "Selecione o cliente", required: true }]}>
                <SelectPesq noMargin disabled={detalhe > 0} title="Cliente" modelo="cliente" placeholder="Selecione o cliente" value={dados.cliente} maxTagTextLength={400} onChange={v => {
                  setDados({ ...dados, cliente: v });
                  if (!!v[0]) listarDadosFin(() => props.fetchFin().get(`/cliente/${v[0].key}`));
                  else setDadosFin(null);
                }} />
              </Form.Item>
            </div>
            <div style={{ flex: 2, marginLeft: 10 }}>
              <Form.Item name="planoContasId" rules={[{ type: "number", message: "Selecione o plano de contas", required: true }]}>
                <SelectFiltros alwaysFetch disabled={detalhe > 0} title="Plano de conta" placeholder="Selecione o plano de contas" modelo="planocontas" value={dados.planoContasId} onChange={v => setDados({ ...dados, planoContasId: v })} />
              </Form.Item>
            </div>
          </div>
          {!!dadosFin && <DadosCobrancaCliente dados={dadosFin} disabled />}
          <Divider style={{ margin: 0, }} />
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", marginRight: 10 }}>
              <LabelPadrao>Emissão</LabelPadrao>
              <DatePickerPadrao disabled={detalhe > 0} value={moment(dados.dataEmissao)} onChange={d => setDados({ ...dados, dataEmissao: d })} />
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", marginRight: 10 }}>
              <LabelPadrao>{dados.repetir ? "Vencimento do primeiro título" : "Vencimento"}</LabelPadrao>
              <DatePickerPadrao disabled={detalhe > 0} value={moment(dados.dataVencimento)} onChange={d => {
                if (moment(d).isBefore(moment())) mensagens.showErro("Data de vencimento deve ser posterior ao dia de hoje!");
                setDados({ ...dados, dataVencimento: moment(d).isBefore(moment()) ? moment() : d });
              }} />
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <LabelPadrao>Competência</LabelPadrao>
              <DatePickerPadrao disabled={detalhe > 0} value={moment(dados.dataCompetencia)} onChange={d => setDados({ ...dados, dataCompetencia: d })} picker="month" />
            </div>
            {dados.dataPagamento && <div style={{ width: "100%", display: "flex", flexDirection: "column", marginLeft: 10 }}>
              <LabelPadrao>Data pagamento</LabelPadrao>
              <DatePickerPadrao disabled value={moment(dados.dataPagamento)} />
            </div>}
          </div>
          <Divider />
          {dados.id <= 0 && <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 10, width: 200 }}>
              <LabelPadrao noMargin>Valor</LabelPadrao>
              <Form.Item name="valor" rules={[{ type: "number", message: "Insira o valor", required: true, min: 0.01, whitespace: true, }]} style={{ margin: 0, padding: 0 }}>
                <InputNumero value={dados.valor} onChange={v => setDados({ ...dados, valor: v })} />
              </Form.Item>
            </div>
            <CheckBoxPadrao style={{ marginTop: 25 }} checked={dados.repetir} onChange={() => setDados({ ...dados, repetir: !dados.repetir })}>Repetir</CheckBoxPadrao>
          </div>}

          {dados.id != -1 && <div style={{ width: "50%", marginRight: 10, display: "flex" }}>
            <div>
              <LabelPadrao noMargin>Valor</LabelPadrao>
              <InputNumero disabled={detalhe > 0} minValue={0} value={dados.valor} onChange={v => setDados({ ...dados, valor: !!v ? v : 0 })} style={{ width: "100%" }} />
            </div>
            {!!dados?.dataPagamento && <div style={{ marginLeft: 10 }}>
              <LabelPadrao noMargin>Valor pago</LabelPadrao>
              <InputNumero disabled minValue={0} value={dados.valorPago} style={{ width: "100%" }} />
            </div>}
          </div>}

          <Collapse style={{ background: "none", marginTop: 25 }} bordered={false}>
            <Panel header="Observações do título" key="1">
              <Input.TextArea placeholder="Insira a observação" value={dados.obs} disabled={detalhe > 0} onChange={v => setDados({ ...dados, obs: v.target.value })} />
            </Panel>
          </Collapse>
        </div>
        <footer style={{ display: "flex", width: "100%", marginTop: 10, justifyContent: "flex-end" }}>
          {!dados?.dataPagamento && <BtnPadrao type="primary" style={{ marginRight: 10 }} loading={isSaving} htmlType="submit">Gravar</BtnPadrao>}
          <BtnPadrao type="primary" ghost onClick={onCancel} disabled={isSaving}>Sair</BtnPadrao>
        </footer>
      </Form>}
      {/* <pre>{JSON.stringify(dados, null, 2)}</pre> */}
    </LoadingContainerPadrao>
  </ModalPadrao>;
}

export default connect(null, { logIn, fetchFin })(ContasDetalhe);
