import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { logIn, fetchFin } from "../../actions";
import Container from "../../components/Container";
import { DatePickerPadrao, LabelPadrao, CheckBoxPadrao, BtnPadrao, SpeedDialPadrao, ModalConfirmPadrao, IconCor, ModalPadrao, LoadingContainerPadrao, CardPadrao } from "../../components/Base";
import { Divider, Select, Input } from "antd";
import { PlusOutlined, PrinterFilled, DeleteFilled, DownloadOutlined, DollarOutlined, FileTextOutlined, FileTextFilled } from "@ant-design/icons";
import SelectFiltros from "../../components/Selects/SelectFiltros";
import TabelaPadrao from "../../components/TabelaPadrao";
import ContasModal from "./ContasModal";
import moment from "moment";
import { useFetch, useSave } from "../../components/Hooks/Conexao";
import mensagens from "../../components/Mensagens";
import SelectPesq from "../../components/Selects/SelectPesq";
import formatarData from "../../Utils/formatarData";
import toLocale from "../../Utils/toLocale";
import { InputNumero } from "../../components/InputNumero";
import styles from "./ContasLayout.module.scss";
import UploadPadrao from "./UploadPadrao";

const dt = new Date();
const hoje = (new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0)).getTime();
const FONT_SIZE = 20;

function Contas({ tipo, ...props }) {
    const [filtros, setFiltros] = useState({ dtIni: moment().startOf('month'), dtFim: moment().startOf('month').add(1, 'months').subtract(1, 'days'), fmt: "vencimento", aberto: true, pago: true });
    const [dadosAlteracao, setDadosAlteracao] = useState({});
    const [openDial, setOpenDial] = useState(false);
    const [marcados, setMarcados] = useState([]);
    const [painel, listarPainel, isLoadingPainel] = useFetch(() => props.fetchFin().post("contas/painel"));
    const [dados, listar, isLoading, setDados] = useFetch(() => props.fetchFin().post("contas/getlist", filtros));
    const [deletarDados, deletando] = useSave(() => props.fetchFin().post("contas/deletar", { id: marcados[0] }), () => { listar(); listarPainel(); setMarcados([]); });
    const [alterarValores, alterando] = useSave(() => props.fetchFin().post("contas/alterar-valores", { id: marcados[0], ...dadosAlteracao }), () => { listar(); listarPainel(); setMarcados([]); setAlteracaoVisible(false); setDadosAlteracao({}); });
    const [baixar, baixando] = useSave(() => props.fetchFin().post("contas/baixar", marcados), () => { listar(); listarPainel(); setMarcados([]); });
    const [alteracaoVisible, setAlteracaoVisible] = useState(false);
    const [detalhe, setDetalhe] = useState(null);
    const [visualizarBoleto, visualizandoBoleto] = useSave();

    const valorTotal = useMemo(() => (marcados.length ? dados?.filter(x => marcados.includes(x.id)) : dados)?.reduce((a, b) => a + b.valor, 0), [marcados, dados]);
    const valorPagoTotal = useMemo(() => (marcados.length ? dados?.filter(x => marcados.includes(x.id)) : dados)?.reduce((a, b) => a + b.valorPago, 0), [marcados, dados]);

    useEffect(() => { listar(); listarPainel(); }, []);

    const listarPainelDetalhe = pn => {
        setDados(null);
        listar(() => props.fetchFin().post("contas/getlist", { ...filtros, tipo, painel: pn }));
    };

    const columns = [
        {
            Header: "",
            accessor: "sd",
            Cell: props => {
                const x = props.row.original;
                let cor = '#bbb';

                if (x.dataPagamento) cor = 'green';
                else if (new Date(x.dataVencimento).getTime() < hoje) cor = 'red';
                else if (new Date(x.dataVencimento).getTime() === hoje) cor = '#ffa500';
                return <IconCor cor={cor} />;
            },
        },
        {
            Header: "Nome",
            accessor: "nome",
            Footer: marcados.length ? marcados.length : dados?.length,
            Cell: p => {
                const x = p.row.original;
                // return <>{x.nome} {x.contemBoleto && <FileTextOutlined title="Boleto gerado" style={{ marginLeft: 8, color: '#1976d2', cursor: 'help' }} />}</>
                return <>{x.nome} {x.contemBoleto && <FileTextFilled
                    onClick={() => visualizarBoleto(() => props.fetchFin().get(`titulo/pdf/${x.id}`), v => typeof v === 'string' && v.length < 500 ? window.open(v) : openPdfFile(v))}
                    title="Boleto gerado" style={{ marginLeft: 8, color: '#1976d2', cursor: 'pointer' }} />}</>
            },
        },
        {
            Header: "Emissão - Vencimento",
            accessor: "emi",
            Cell: props => <span> {formatarData(props.row.original.dataEmissao).DATA} - {formatarData(props.row.original.dataVencimento).DATA}</span>,
            tipo: "center",
        },
        {
            Header: "Parcela",
            accessor: "numero",
            tipo: "int",
        },
        {
            Header: "Valor",
            accessor: "valor",
            tipo: "rs",
            Footer: toLocale(valorTotal)
        },
        {
            Header: "Valor pago",
            accessor: "valorPago",
            tipo: "rs",
            Footer: toLocale(valorPagoTotal)
        },
        {
            Header: "Baixa",
            accessor: "dataPagamento",
            Cell: props => <span>{props.row.original.dataPagamento && formatarData(props.row.original.dataPagamento).DATA}</span>,
            tipo: "center",
        },
        {
            Header: "Plano de contas",
            accessor: "planoConta",
        },
    ];

    function validarIsBaixado(cb) {
        var isBaixado = false;
        marcados.map(x => {
            if (isBaixado) return;
            if (dados.find(s => s.id == x).dataPagamento !== null) { isBaixado = true; return mensagens.showWarning("Existem títulos já baixados!"); }
        });
        if (!isBaixado) cb(true);
    }

    function baixarContas() {
        let titulos = [];
        marcados.forEach(id => {
            const dado = dados.find(x => x.id === id);
            titulos.push(dado);
        });
        const clientes = titulos.map(x => x.clienteId).filter((v, i, a) => a.indexOf(v) !== i.clienteId);
        const clientesIg = clientes.filter((v, i, a) => a.indexOf(v) === i);
        function validarArraysIguais(a, b) {
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length !== b.length) return false;
            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }
            return true;
        }
        if (!validarArraysIguais(clientes, clientesIg)) return mensagens.showWarning("Selecionados títulos de mesmo cliente!");

        validarIsBaixado(() => ModalConfirmPadrao("Deseja baixar esta conta?", "um registro baixado não poderá ser revertido", "Sim", "Não", () => baixar()));
    }

    function deletarConta() {
        if (marcados.length > 1) return mensagens.showWarning("Delete apenas um título por vez!");
        validarIsBaixado(() => ModalConfirmPadrao("Deseja deletar conta?", "Todos registros vinculados a este serão excluidos!", "Sim", "Não", () => deletarDados()));
    }

    return <Container loading={isLoading || deletando || baixando || visualizandoBoleto}>
        <Divider orientation="left">Contas Receber</Divider>
        <LoadingContainerPadrao loading={isLoadingPainel} spinnerProps={{ fontSize: 35 }}>
            <div className={styles.gridCard}>
                <CardPadrao className={styles.cards} color="red" value={painel && toLocale(painel.vencido)} title="Vencido" onClick={() => listarPainelDetalhe('venc')} />
                <CardPadrao className={styles.cards} color="orange" value={painel && toLocale(painel.hoje)} title="A receber hoje" onClick={() => listarPainelDetalhe('hoje')} />
                <CardPadrao className={styles.cards} color="gray" value={painel && toLocale(painel.mes)} title="A receber este mês" onClick={() => listarPainelDetalhe('mes')} />
                <CardPadrao className={styles.cards} color="green" value={painel && toLocale(painel.pago)} title="Recebido este mês" onClick={() => listarPainelDetalhe('pago')} />
            </div>
        </LoadingContainerPadrao>

        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: 350 }}>
                <SelectPesq title="Cliente" modelo="clienteall" placeholder="Selecione o cliente" value={filtros.cliente} onChange={v => setFiltros({ ...filtros, cliente: v })} />
            </div>
            <div style={{ margin: "0 10px", width: 150 }}>
                <LabelPadrao>Filtrar</LabelPadrao>
                <Select style={{ width: "100%" }} value={filtros.fmt} onChange={v => setFiltros({ ...filtros, fmt: v })}>
                    <Select.Option key="1" value="vencimento">Vencimento</Select.Option>
                    <Select.Option key="2" value="baixa">Baixa</Select.Option>
                </Select>
            </div>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10 }}>
                    <LabelPadrao>Período</LabelPadrao>
                    <DatePickerPadrao value={filtros.dtIni} onChange={d => setFiltros({ ...filtros, dtIni: d })} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <DatePickerPadrao value={filtros.dtFim} onChange={d => setFiltros({ ...filtros, dtFim: d })} />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginLeft: 10, marginRight: 10 }}>
                <LabelPadrao>Título</LabelPadrao>
                <div style={{ paddingTop: 5 }}>
                    <CheckBoxPadrao checked={filtros.pago} onChange={() => setFiltros({ ...filtros, pago: !filtros.pago })}>Recebido</CheckBoxPadrao>
                    <CheckBoxPadrao checked={filtros.aberto} onChange={() => setFiltros({ ...filtros, aberto: !filtros.aberto })}>Em aberto</CheckBoxPadrao>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <BtnPadrao type="primary" ghost onClick={listar}>Listar</BtnPadrao>
            </div>
        </div>
        <div style={{ marginTop: 25 }}>
            <SpeedDialPadrao
                open={openDial}
                onClose={() => setOpenDial(false)}
                onOpen={() => setOpenDial(true)}
                direction="right"
                ActionList={
                    marcados.length
                        ? [
                            { title: "adicionar", onClick: () => setDetalhe(-1), icon: <PlusOutlined style={{ fontSize: FONT_SIZE }} /> },
                            {
                                title: "baixar",
                                onClick: () => baixarContas(),
                                icon: <DownloadOutlined style={{ fontSize: FONT_SIZE }} />,
                            },
                            {
                                title: "alterar valores",
                                onClick: () => marcados.length > 1 ? mensagens.showWarning("Altere apenas um título por vez!") : validarIsBaixado(() => setAlteracaoVisible(true)),
                                icon: <DollarOutlined style={{ fontSize: FONT_SIZE + 3 }} />,
                            },
                            {
                                title: "imprimir",
                                onClick: () => window.print(),
                                icon: <PrinterFilled style={{ fontSize: FONT_SIZE }} />,
                            },
                            {
                                title: "deletar",
                                onClick: () => deletarConta(),
                                icon: <DeleteFilled style={{ fontSize: FONT_SIZE }} />,
                            },
                        ]
                        : [
                            { title: "adicionar", onClick: () => setDetalhe(-1), icon: <PlusOutlined style={{ fontSize: FONT_SIZE }} /> },
                            {
                                title: "importar arquivo retorno", onClick: () => { }, icon: <UploadPadrao
                                    modalConfirmTitle="Deseja baixar este arquivo?"
                                    onOk={() => {
                                        mensagens.showSucesso("O arquivo retorno foi baixado!");
                                        listar();
                                        listarPainel();
                                    }}
                                    error={e => mensagens.showErro(e)}>
                                    <DownloadOutlined style={{ fontSize: FONT_SIZE, color: "gray" }} />
                                </UploadPadrao>
                            },
                            { title: "imprimir", onClick: () => window.print(), icon: <PrinterFilled style={{ fontSize: FONT_SIZE }} /> },
                        ]
                } />
        </div>
        <div>{dados && <TabelaPadrao columns={columns} style={{ marginTop: 25 }} data={dados} setEdit={v => setDetalhe(v.id)} setMarcados={m => setMarcados(m)} />}</div>
        <ContasModal visible={!!detalhe} detalhe={detalhe} onOk={() => { setDetalhe(null); listar(); listarPainel(); }} onCancel={() => setDetalhe(null)} />
        {/* <pre>{JSON.stringify(dados, null, 2)}</pre> */}
        <ModalAlterarValores
            dados={dadosAlteracao}
            setDados={v => setDadosAlteracao(v)}
            onOk={alterarValores}
            onCancel={() => { setAlteracaoVisible(false); setDadosAlteracao({}); }}
            visible={alteracaoVisible}
            loading={alterando}
        />
    </Container>;
}

function ModalAlterarValores({ dados, setDados, loading, visible, onOk, onCancel }) {
    return <ModalPadrao footer={<>
        <BtnPadrao type="primary" loading={loading} onClick={onOk}>Gravar</BtnPadrao>
        <BtnPadrao type="primary" ghost onClick={onCancel}>Sair</BtnPadrao>
    </>} title="Alterar valores" visible={visible} onCancel={onCancel}>
        <LoadingContainerPadrao loading={loading}>
            <LabelPadrao noMargin>Valor</LabelPadrao>
            <InputNumero value={dados.valor} onChange={v => setDados({ ...dados, valor: !!v ? v : 0 })} />

            <LabelPadrao>Plano de Contas</LabelPadrao>
            <SelectFiltros alwaysFetch modelo="planocontas" value={dados.planoContasId} onChange={v => setDados({ ...dados, planoContasId: v })} allowClear />

            <LabelPadrao>Observações</LabelPadrao>
            <Input.TextArea placeholder="Insira a observação" value={dados.obs} onChange={v => setDados({ ...dados, obs: v.target.value })} rows={5} />

            <div style={{ marginTop: 10 }}>
                <span style={{ opacity: 0.5 }}>*Todos os registros vinculados a este terão os valores alterados!</span>
            </div>
        </LoadingContainerPadrao>
    </ModalPadrao>;
}

const openPdfFile = (data) => {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i);
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
};

export default connect(null, { logIn, fetchFin })(Contas);