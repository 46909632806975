import React from "react";
import Menu from "../containers/Menu/Menu";
import { LoadingScreen } from "./Diversos";

export default function ({ children, showMenu = true, loading, ...props }) {
  return (
    <>
      {showMenu && <Menu loading={loading} />}
      {loading && <LoadingScreen loading={loading} />}
      <div className="container-padrao" {...props}>
        {children}
      </div>
    </>
  );
}
